:root{
  --main-bg-color: #FFF;
  --text-color: #212529;
  --box-bg-color: #e9ecef;
  --sidebar-bg-color: #f7f7f7;
  --input-bg-color: #FFF;
  --border-color: #ececec;
  --button-color: #3282b8;
}

body.dark{
  --main-bg-color: #282c35;
  --text-color: #FFF;
  --box-bg-color: #333;
  --sidebar-bg-color: #444;
  --input-bg-color: #777;
  --border-color: #555;
  --button-color: #46b5d1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  transition: background .5s ease-out;
}

/**
* Misc
*/
button.btn-primary{ background-color: var(--button-color); border-color: var(--button-color); }
.contentarea{ min-height: 300px; }
.form-control{ background-color: var(--input-bg-color); }
