.item-placeholder { width: 100%; height: 300px; box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 0.2); margin-bottom: 30px; }
.shimmer-effect {
  background: #f6f6f6; background: linear-gradient(to right, #d4d4d4 8%, #e4e2e2 18%, #d4d4d4 33%); background-size: 800px 104px; -webkit-animation: placeHolderShimmer 2s infinite; animation: placeHolderShimmer 2s infinite; opacity: 0.2; }
@-webkit-keyframes placeHolderShimmer {
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
}
@keyframes placeHolderShimmer {
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
}