.toggle-theme{ position: absolute; top: 35px; right: 40px; }
.switch { display: inline-block; height: 28px; position: relative; width: 55px; }
.switch input { display:none; }
.slider { background-color: #0f1114; bottom: 0; cursor: pointer; left: 0; position: absolute; right: 0; top: 0; transition: .4s; } 
.slider:before { background-color: #fff; bottom: 4px; content: ""; height: 20px; left: 4px; position: absolute; transition: .4s; width: 20px; z-index: 9; }
.slider:after{ content: ""; position: absolute; background-repeat: no-repeat; width: 20px; height: 20px; background-size: contain; top: 3px; transition: all .3s ease-out; z-index: 5; background-image: url(../../assets/images/sun.png); right: 5px; }
.slider.dark:after{ background-image: url(../../assets/images/moon.png); left: 5px; }
input:checked + .slider { background-color: #0f1114; }
input:checked + .slider:before { transform: translateX(26px); }
.slider.round { border-radius: 34px; }
.slider.round:before { border-radius: 50%; }